import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { CheckCircleIcon } from '@chakra-ui/icons'
import {
  Alert,
  AlertIcon,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Input,
  Select,
  Text,
  VStack,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'

import { FormLabelWithBadge } from '@/components/parts/FormLabelWithBadge'
import { GenderOptions } from '@/const/registrationFormValues'
import { MainButton } from '@/modules/components/MainButton'
import { calcAge } from '@/modules/utils/calcAge'
import { GTMClickItemName } from '@/modules/utils/gtm/types/common'
import { RegistrationRequest } from '@/oas/talent/api'
import { checkDate } from '@/utils/validation/checkDate'

interface Props {
  talentInformation: RegistrationRequest
  onBackStep: () => void
  submit: (v: BasicInformationFormValues) => void
}

export type BasicInformationFormValues = {
  last_name: string
  first_name: string
  last_name_kana: string
  first_name_kana: string
  birthday: string
  gender_master_id: number
}

/**
 * 求職者基本情報登録を行うコンポーネント
 * 独立したReactHookFormを用いるが、submit関数を経由し、親コンポーネントで入力状態管理を行う
 */
export const BasicInformation: React.FC<Props> = ({
  talentInformation,
  onBackStep,
  submit,
}: Props) => {
  const UserSchema = z.object({
    last_name: z.string().min(1, '氏名は必須です。'),
    first_name: z.string().min(1, '氏名は必須です。'),
    last_name_kana: z
      .string()
      .min(1, '氏名（ふりがな）は必須です。')
      .regex(/^[ぁ-んー]*$/, '氏名（ふりがな）はひらがなで入力してください。'),
    first_name_kana: z
      .string()
      .min(1, '氏名（ふりがな）は必須です。')
      .regex(/^[ぁ-んー]*$/, '氏名（ふりがな）はひらがなで入力してください。'),
    birthday: z
      .string()
      .min(1, '生年月日は必須です。')
      .regex(/[0-9]{8}/, '生年月日は8桁の半角数字で入力してください。')
      .refine((data) => checkDate(data, 'YYYYMMDD'), {
        message: '正しい日付を入力してください。',
      }),
    gender_master_id: z.union([
      z.number().positive('性別は必須です。'),
      z.string().min(1, '性別は必須です。'),
    ]),
  })

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useForm<BasicInformationFormValues>({ resolver: zodResolver(UserSchema) })

  const values = watch()

  const isNameValidation =
    (errors.first_name && errors.first_name?.message?.length !== 0) ||
    (errors.last_name && errors.last_name?.message?.length !== 0)
  const isNameKanaValidation =
    (errors.first_name_kana && errors.first_name_kana?.message?.length !== 0) ||
    (errors.last_name_kana && errors.last_name_kana?.message?.length !== 0)

  useEffect(() => {
    // 戻ってきた時に以前の値を保持する
    setValue('last_name', talentInformation.last_name)
    setValue('first_name', talentInformation.first_name)
    setValue('last_name_kana', talentInformation.last_name_kana)
    setValue('first_name_kana', talentInformation.first_name_kana)
    setValue('birthday', talentInformation.birthday)
    if (talentInformation.gender_master_id)
      setValue('gender_master_id', talentInformation.gender_master_id)
  }, [])

  const nowAge = useMemo(() => {
    // 値が8文字未満 or エラーがある状態では計算しない
    if (
      !values.birthday ||
      values.birthday.length < 8 ||
      (errors.birthday && errors.birthday?.message?.length !== 0)
    ) {
      return
    }
    return calcAge(values.birthday)
  }, [values.birthday, errors.birthday])

  const nowAgeElm = () => {
    if (nowAge === undefined) return

    // 16歳未満の場合はエラー表示
    if (nowAge < 16) {
      return (
        <Alert
          p={2}
          mt={2}
          status="error"
          alignItems="flex-start"
          bg="#FFF5F5"
          color="#E53E3E"
          borderRadius="4px"
          fontSize="14px"
        >
          <AlertIcon w="20px" h="20px" color="#E53E3E" marginInlineEnd="8px" />
          16歳未満はご利用いただけません。
        </Alert>
      )
    }

    return (
      <Flex
        justifyContent="flex-start"
        alignItems="center"
        p={1}
        mt={2}
        bg="surface.secondary"
        borderRadius="4px"
      >
        <CheckCircleIcon w="16px" h="16px" color="brand.green" />
        <Text fontSize="14px" color="text.primary" ml={1}>
          {nowAge}歳
        </Text>
      </Flex>
    )
  }

  const isDisabled =
    !values.last_name ||
    !values.first_name ||
    !values.last_name_kana ||
    !values.first_name_kana ||
    !values.birthday ||
    !nowAge ||
    nowAge < 16 ||
    !values.gender_master_id

  return (
    <VStack
      as="form"
      w="100%"
      spacing="24px"
      onSubmit={handleSubmit((v) => {
        submit(v)
      })}
      noValidate
    >
      <Flex gap={1} w="100%">
        <FormControl key="氏名" isInvalid={isNameValidation}>
          <FormLabelWithBadge labelText="氏名" isRequired />
          <Flex gap="4px">
            <Input
              maxLength={255}
              placeholder="例）新宿"
              _placeholder={{ color: '#A0AEC0' }}
              {...register('last_name')}
              isInvalid={isNameValidation}
              onBlur={() => trigger('last_name')}
              data-gtm-click-item={'registration_agent_profile_name_input' as GTMClickItemName}
            />
            <Input
              maxLength={255}
              placeholder="例）太郎"
              _placeholder={{ color: '#A0AEC0' }}
              {...register('first_name')}
              onBlur={() => trigger('first_name')}
              data-gtm-click-item={'registration_agent_profile_name_input' as GTMClickItemName}
            />
          </Flex>
          <FormErrorMessage>
            {errors.first_name?.message
              ? errors.first_name?.message
              : errors.last_name?.message
              ? errors.last_name?.message
              : ''}
          </FormErrorMessage>
        </FormControl>
      </Flex>
      <Flex gap={1} w="100%">
        <FormControl key="氏名（ふりがな）" isInvalid={isNameKanaValidation}>
          <FormLabelWithBadge labelText="氏名（ふりがな）" isRequired />
          <Flex gap={1}>
            <Input
              maxLength={255}
              placeholder="例）しんじゅく"
              _placeholder={{ color: '#A0AEC0' }}
              {...register('last_name_kana')}
              onBlur={() => trigger('last_name_kana')}
              data-gtm-click-item={'registration_agent_profile_kana_input' as GTMClickItemName}
            />
            <Input
              maxLength={255}
              placeholder="例）たろう"
              _placeholder={{ color: '#A0AEC0' }}
              {...register('first_name_kana')}
              onBlur={() => trigger('first_name_kana')}
              data-gtm-click-item={'registration_agent_profile_kana_input' as GTMClickItemName}
            />
          </Flex>
          <FormErrorMessage>
            {errors.first_name_kana?.message
              ? errors.first_name_kana?.message
              : errors.last_name_kana?.message
              ? errors.last_name_kana?.message
              : ''}
          </FormErrorMessage>
        </FormControl>
      </Flex>
      <FormControl
        isInvalid={errors.birthday && errors.birthday?.message?.length !== 0}
        _last={{ mb: '24px' }}
      >
        <FormLabelWithBadge labelText="生年月日" isRequired />
        <Input
          maxLength={8}
          placeholder="例）20030101"
          _placeholder={{ color: '#A0AEC0' }}
          {...register('birthday')}
          onBlur={() => trigger('birthday')}
          pattern="[0-9]*"
          data-gtm-click-item={'registration_agent_profile_birthday_input' as GTMClickItemName}
        />
        <FormHelperText fontSize="12px">2003/1/1生まれの場合の入力例「20030101」</FormHelperText>
        <FormErrorMessage>{errors.birthday?.message}</FormErrorMessage>
        {nowAgeElm()}
      </FormControl>
      <FormControl
        isInvalid={errors.gender_master_id && errors.gender_master_id?.message?.length !== 0}
        _last={{ mb: '24px' }}
      >
        <FormLabelWithBadge labelText="性別" isRequired />
        <Select
          color={values.gender_master_id ? 'text.primary' : '#A0AEC0'}
          placeholder="選択してください"
          _placeholder={{ color: '#A0AEC0' }}
          {...register('gender_master_id')}
          onBlur={() => trigger('gender_master_id')}
          data-gtm-click-item={'registration_agent_profile_gender_select' as GTMClickItemName}
        >
          {GenderOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
        <FormErrorMessage>{errors.gender_master_id?.message}</FormErrorMessage>
      </FormControl>
      <Flex w="100%" gap="8px">
        <MainButton mode="secondary" borderColor="border.primary" h="48px" p="10px 32px">
          <Text
            fontSize="14px"
            fontWeight={700}
            lineHeight="20px"
            color="text.primary"
            onClick={onBackStep}
            data-gtm-click-item={'registration_agent_back_button' as GTMClickItemName}
          >
            戻る
          </Text>
        </MainButton>
        <MainButton
          mode="primary"
          type="submit"
          borderColor="border.primary"
          w="100%"
          h="48px"
          p="10px 32px"
          isDisabled={isDisabled}
          data-gtm-click-item={'registration_agent_step_profile_button' as GTMClickItemName}
        >
          <Text fontSize="14px" fontWeight={700} lineHeight="20px">
            次へ
          </Text>
        </MainButton>
      </Flex>
    </VStack>
  )
}
