import dayjs from 'dayjs'
import isLeapYear from 'dayjs/plugin/isLeapYear' // import plugin

import 'dayjs/locale/ja' // import locale

dayjs.extend(isLeapYear) // use plugin
dayjs.locale('ja') // use locale

export const calcAge = (birthday: string) => {
  const year = birthday.substring(0, 4)
  const month = birthday.substring(4, 6)
  const day = birthday.substring(6, 8)

  const today = dayjs()
  return today.diff(`${year}-${month}-${day}`, 'year')
}
