import { axiosInstance } from '@/api/axiosInstance'
import { AuthApi, RegistrationRequest } from '@/oas/talent/api'

import { getTalentApiUrl } from './apiHostNameSwitcher'

const authApi = new AuthApi(undefined, getTalentApiUrl(), axiosInstance)

/**
 * 求職者登録情報（ステップ1）を受ける
 * LINE認証の開始のために、CSRF対策用トークンをバックエンドのセッション値として発行する処理なども行われる
 * そうした性質から、「登録開始」の趣が強いため、動詞startを命名に利用した
 */
export const startTalentRegistrationViaLineAuthentication = (request: RegistrationRequest) => {
  return authApi.lineRegistration(request, {
    withCredentials: true,
  })
}
