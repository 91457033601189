import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  Alert,
  AlertIcon,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'

import { FormLabelWithBadge } from '@/components/parts/FormLabelWithBadge'
import { appUrl } from '@/config'
import { useUserAgent } from '@/hooks/useUserAgent'
import { LINEButton } from '@/modules/components/LINEButton'
import { SmsButton } from '@/modules/components/SMSButton'
import { privacyPolicyPage } from '@/modules/constant'
import { GTMClickItemName } from '@/modules/utils/gtm/types/common'
import { RegistrationRequest } from '@/oas/talent/api'

interface Props {
  isVerifyLoading: boolean
  isDuplicate: boolean
  talentInformation: RegistrationRequest
  setTalentInformation: (talentInformation: RegistrationRequest) => void
  lineSubmit: (talent: RegistrationRequest) => Promise<string | undefined>
  checkMobile: (phoneNumber: string, email: string) => void
}

export const ContactInformation: React.FC<Props> = ({
  isVerifyLoading,
  isDuplicate,
  talentInformation,
  setTalentInformation,
  lineSubmit,
  checkMobile,
}: Props) => {
  const { isWebView } = useUserAgent()
  const UserSchema = z.object({
    email: z
      .string()
      .min(1, 'メールアドレスは必須です。')
      .email('正しいメールアドレスを入力してください。'),
    phone_number: z
      .string()
      .min(1, '電話番号を入力してください。')
      .refine((value) => /^[0-9]+$/.test(value), '正しい電話番号を入力してください。'),
  })

  const {
    register,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<RegistrationRequest>({ resolver: zodResolver(UserSchema) })

  const values = watch()

  const lineLogin = async () => {
    // WHY: Safari環境だとクリックからwindow.openまでにawaitが挟まっているとポップアップブロックされるため、openしてから書き換える
    const newTab = window.open('')
    const talentRequest = {
      ...talentInformation,
      email: values.email,
      phone_number: values.phone_number,
    }
    // dataLayerからUTMパラメータを取得
    const utmParameters = window.dataLayer
      ? window.dataLayer.find((obj) => obj.event === 'dataLayerSync')
      : null
    if (utmParameters) {
      Object.assign(talentRequest, {
        utm_source: utmParameters.utm_source,
        utm_medium: utmParameters.utm_medium,
        utm_campaign: utmParameters.utm_campaign,
        utm_term: utmParameters.utm_term,
        utm_content: utmParameters.utm_content,
      })
    }
    const redirectUrl = await lineSubmit(talentRequest)
    if (redirectUrl && newTab) {
      newTab.location.href = redirectUrl
    } else {
      newTab?.close()
    }
  }
  const changeToSMS = () => {
    setTalentInformation({
      ...talentInformation,
      email: values.email,
      phone_number: values.phone_number,
    })
    checkMobile(values.phone_number, values.email)
  }

  const isDisabled =
    !values.email ||
    !values.phone_number ||
    values.phone_number.length < 11 ||
    Boolean(errors.email) ||
    Boolean(errors.phone_number)

  useEffect(() => {
    // 戻ってきた時に以前の値を保持する
    setValue('email', talentInformation.email)
    setValue('phone_number', talentInformation.phone_number)
  }, [])

  return (
    <VStack as="form" w="100%" spacing="24px" noValidate>
      <FormControl isInvalid={errors.email && errors.email?.message?.length !== 0}>
        <FormLabelWithBadge labelText="メールアドレス" isRequired />
        <Input
          type="email"
          placeholder="例）taro.s@exmple.com"
          _placeholder={{ color: '#A0AEC0' }}
          {...register('email')}
          onBlur={() => trigger('email')}
          data-gtm-click-item={'registration_agent_email_input' as GTMClickItemName}
        />
        <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={errors.phone_number && errors.phone_number?.message?.length !== 0}>
        <FormLabelWithBadge labelText="電話番号（ハイフンなし）" isRequired />
        <Input
          maxLength={11}
          placeholder="例）09012345678"
          _placeholder={{ color: '#A0AEC0' }}
          {...register('phone_number')}
          onBlur={() => trigger('phone_number')}
          pattern="[0-9]*"
          data-gtm-click-item={'registration_agent_phone_input' as GTMClickItemName}
        />
        <FormErrorMessage>{errors.phone_number?.message}</FormErrorMessage>
      </FormControl>
      {isDuplicate && (
        <VStack spacing="8px" fontSize="14px">
          <Alert
            p="8px"
            status="error"
            alignItems="flex-start"
            bg="#FFF5F5"
            color="#E53E3E"
            borderRadius="4px"
          >
            <AlertIcon color="#E53E3E" marginInlineEnd="8px" />
            ご入力いただいた連絡先はZキャリアの会員登録にはご利用できません。
          </Alert>
          <Alert p="8px" display="inline-block" bg="blue.50" borderRadius="4px">
            会員登録とログインをお間違えではないですか？ すでに会員登録済みの方は
            <Link
              color="text.link"
              href="/login"
              target="_blank"
              textDecoration="none"
              _hover={{ textDecoration: 'none' }}
            >
              ログインから
            </Link>
            ご利用ください。
          </Alert>
        </VStack>
      )}
      <Flex flexDirection="column" gap="16px">
        <VStack
          w="full"
          alignItems="flex-start"
          bgColor="surface.secondary"
          color="text.secondary"
          p="8px"
          fontSize="11px"
          borderRadius="4px"
          gap="8px"
        >
          <Text fontWeight="bold" fontSize="12px" lineHeight="150%">
            注意事項
          </Text>
          <Text textStyle="talent.body.sm">
            ※以下の「電話番号(SMS)で続ける」{isWebView ? '' : 'または「LINEで続ける」'}
            をクリックし、認証を続けることにより会員登録が完了致します。利用規約、プライバシーポリシーを十分ご理解の上、会員登録頂きますようお願いいたします。
          </Text>
          <Text textStyle="talent.body.sm">
            ※会員登録を頂いた方は、本サービスをご利用頂けると共に、登録情報をもとにキャリアアドバイザーから求人情報をご紹介させて頂きます。尚、ご登録情報によっては、求人のご紹介ができない場合がございますのでご了承ください。
          </Text>
        </VStack>
        <Text textStyle="talent.body.sm">
          本サービスの
          <Link
            color="text.link"
            href={`${appUrl}/docs/terms`}
            target="_blank"
            textDecoration="none"
            _hover={{ textDecoration: 'none' }}
          >
            利用規約
          </Link>
          と
          <Link
            color="text.link"
            href={privacyPolicyPage}
            target="_blank"
            textDecoration="none"
            _hover={{ textDecoration: 'none' }}
          >
            プライバシーポリシー
          </Link>
          に同意して
        </Text>

        <VStack w="100%" spacing="8px">
          <SmsButton
            id="sign-in-button"
            w="100%"
            onClick={changeToSMS}
            isDisabled={isDisabled}
            isLoading={isVerifyLoading}
          />
          {!isWebView && <LINEButton w="100%" onClick={lineLogin} isDisabled={isDisabled} />}
        </VStack>
      </Flex>
    </VStack>
  )
}
