import dayjs from 'dayjs'

/**
 * 有効な日付かどうかを判定する関数
 * @param date
 * @param format
 * @returns
 */
export const checkDate = (date: string, format: string) => {
  return dayjs(date, format).format(format) === date
}
